/* Base Container styles */
.countdown-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 0.6em;
  margin-bottom: 0.6em;
  /* Slight right-shift on larger screens */
  transform: translateX(3vw);
}

/* Base Time block styles */
.time-block {
  background-color: #222;
  color: #fff;
  padding: 1rem 1.5rem;
  border-radius: 8px;
  text-align: center;
  flex: 1 1 80px;
  min-width: 80px;
}

/* Value styles with dynamic font sizing */
.value {
  display: block;
  /* Scales between 1.5rem and 2.5rem (or 5vw in-between) */
  font-size: clamp(1.5rem, 5vw, 2.5rem);
  font-weight: bold;
  line-height: 1;
}

/* Label styles */
.label {
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 0.25rem;
}

/* Finished message style */
.countdown-finished {
  font-size: 1.5rem;
  font-weight: bold;
  color: #d13333;
  text-align: center;
  margin-top: 2rem;
}

/* Media Query for max-width: 1200px */
@media (max-width: 1200px) {
  .time-block {
    padding: 0.9rem 1.3rem;
    min-width: 75px;
  }
  .value {
    /* Slightly reduce the font scaling */
    font-size: clamp(1.4rem, 4.5vw, 2.2rem);
  }
  .label {
    font-size: 0.85rem;
  }
  .countdown-container {
    transform: translateX(0);
    padding: 0 1rem; /* Added side padding so the boxes don't hug the edges */
  }
}

/* Media Query for max-width: 768px */
@media (max-width: 768px) {
  .countdown-container {
    transform: translateX(0);
    padding: 0 1rem; /* Added side padding so the boxes don't hug the edges */
  }
  .time-block {
    padding: 0.8rem 1rem;
    min-width: 60px;
  }
  .value {
    font-size: clamp(1.3rem, 4vw, 2rem);
  }
  .label {
    font-size: 0.8rem;
  }
}

/* Media Query for max-width: 480px */
@media (max-width: 480px) {
  .time-block {
    padding: 0.7rem 0.8rem;
    min-width: 50px;
  }
  .value {
    font-size: clamp(1.2rem, 3.5vw, 1.8rem);
  }
  .label {
    font-size: 0.75rem;
  }
}
