.faq {
  display: block;
  position: relative;
  height: fit-content;
  width: 40%;
  font-size: 1em;
  margin: 1em 0;
  z-index: 1;

  > .airplane-left {
    display: block;
    position: absolute;
    top: 0.8em;
    left: -2.4em;

    > img {
      width: 100%;
      height: auto;
    }
  }

  a {
    color: inherit;
  }

  > .faq-checkbox {
    display: none;
  }

  > .faq-checkbox:checked + .faq-label {
    transition: 2s all ease;

    > .faq-arrow {
      transform: rotateZ(180deg);
      user-select: none;
    }
  }

  > .faq-checkbox:checked + .faq-label + .faq-answer {
    height: fit-content;
    opacity: 1;
    padding: 10px;
  }

  > .faq-label {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    height: 4em; /* Set fixed height to accommodate two lines of text */
    padding: 1em;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    gap: 0.5em;
    cursor: pointer;

    background: #fff;
    border: 2px solid #000;
    border-radius: 0.5em; /* Slightly rounded corners */
    box-shadow: 5px 5px 0px 0px #f3ae4a; /* Offset yellow box shadow */
    z-index: 1;

    > .faq-question {
      text-align: center; /* Center text horizontally */
      font-family: "Changa", sans-serif; /* Use Changa font */
      font-size: 1.4em;
      font-weight: 800;
      height: 100%; /* Full height to center vertically */
      line-height: 1.4em; /* Adjust line height */
      display: flex; /* Enable flexbox for centering */
      align-items: center; /* Center vertically */
      justify-content: center; /* Center horizontally */
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2; /* Limit to two lines */
      -webkit-box-orient: vertical;
      flex: 1; /* Allow text to take remaining space */
      z-index: 1;
    }

    > .faq-arrow {
      display: block;
      position: relative;
      font-weight: 700;
      transition: 0.3s all ease;
      margin-left: 0.5em; /* Add some space between text and arrow */
    }

    > .arrow-sign {
      position: absolute;
      top: -6.5em; /* Move higher */
      right: 0.5em; /* Adjust to not interfere with text */
      width: 140px; /* Adjust size as needed */
      height: auto;
      z-index: 0;
    }
  }

  > .faq-answer {
    transition: 0.5s all ease;
    height: 0;
    opacity: 0;
    position: relative;
    width: 90%;
    font-size: 1em;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.7);
    text-align: left;
    font-family: "Changa", sans-serif; /* Use Changa font */
    margin: 0;
  }
}

@media screen and (max-width: 1400px) {
  .faq {
    > .airplane-left {
      display: block;
      position: absolute;
      top: 1em;
      left: -2.4em;

      > img {
        width: 80%;
        height: auto;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .faq {
    > .airplane-left {
      display: block;
      position: absolute;
      top: 1.4em;
      left: -2em;

      > img {
        width: 60%;
        height: auto;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .faq {
    > .airplane-left {
      display: block;
      position: absolute;
      top: 1.5em;
      left: -2.3em;

      > img {
        width: 50%;
        height: auto;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .faq {
    > .faq-label {
      cursor: pointer;
      > .faq-question {
      }

      > .faq-arrow {
        display: none;
      }
    }
  }
}
