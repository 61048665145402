.events-schedule-section {
  width: 100%;
  height: 100%;

  padding: 1em;
}

.tabs {
  position: relative;
}

.schedule-tabs {
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: 2em;
  margin-bottom: 1em;

  border-bottom: 2px dashed rgba($color: #000000, $alpha: 0.2);
  border-width: 1px;
  width: 100%;

  > .tab-button {
    font-size: 1em;
    background: none;
    color: inherit;
    border: none;
    box-sizing: border-box;
    color: rgb(0, 0, 0, 0.7);

    margin-bottom: -2px;
    width: 33.3%;

    transition: border-color 0.3s;

    padding: 0.5em;
    cursor: pointer;
    &:hover {
      // border-bottom: 2px solid rgb(0, 0, 0, 0.2);
    }
    &:first-child._active ~ .slider {
      margin-left: 0;
    }
    &:nth-child(2)._active ~ .slider {
      transform: translateX(100%);
    }
    &:nth-child(3)._active ~ .slider {
      transform: translateX(200%);
    }
  }

  .slider {
    display: block;
    position: absolute;
    width: 33.3%;
    height: 3px;
    background-color: #000;
    bottom: 0;
    transition: 0.5s all ease;
  }
}

.schedule-table {
  border-collapse: collapse;
  width: 100%;
  height: 100%;

  thead tr {
    border-bottom: 1px solid #000000;
  }

  tbody tr {
    td {
      border-bottom: 2px dashed rgba($color: #000000, $alpha: 0.2);
      border-width: 1px;
      &:first-child {
        border-bottom: none;
      }
    }
    text-align: left;

    &:last-child {
      border-bottom: none;
    }
  }

  td.img-container {
    width: 10px;
    img {
      width: 25px;
    }
  }

  td.time {
    color: rgb(0, 0, 0, 0.9);
    width: 24%;
  }

  td.event {
    width: 50%;
    color: rgb(0, 0, 0, 0.7);
    font-weight: bold;
    font-family: "crimson-pro";
  }

  td.location {
    color: rgb(0, 0, 0, 0.7);
    text-decoration: underline;
  }

  th,
  td {
    padding: 0.5em 1em;
  }
}

@media screen and (max-width: 1000px) {
  .schedule-table {
    td.img-container {
      width: 10px;
      img {
        width: 15px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .schedule-table {
    font-size: 11px;
  }
}
