.footprints-animation {
  position: absolute;
  bottom: 63%; /* Adjusted position to lower the footprints */
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 3em; /* Increased gap for more separation */
}

.footprint {
  position: absolute;
  animation: walk 10s forwards infinite; /* Repeat indefinitely */
}

.footprint:nth-child(1) {
  top: 0;
  left: 0;
  animation-delay: 0s;
}

.footprint:nth-child(2) {
  top: 1.5em; /* Slightly steeper */
  left: 3em;
  animation-delay: 0.5s;
}

.footprint:nth-child(3) {
  top: 3em; /* Slightly steeper */
  left: 6em;
  animation-delay: 1s;
}

.footprint:nth-child(4) {
  top: 4.5em; /* Slightly steeper */
  left: 9em;
  animation-delay: 1.5s;
}

.footprint:nth-child(5) {
  top: 6em; /* Slightly steeper */
  left: 12em;
  animation-delay: 2s;
}

.footprint:nth-child(6) {
  top: 7.5em; /* Slightly steeper */
  left: 15em;
  animation-delay: 2.5s;
}

.footprint:nth-child(7) {
  top: 9em; /* Slightly steeper */
  left: 18em;
  animation-delay: 3s;
}

.footprint:nth-child(8) {
  top: 10.5em; /* Slightly steeper */
  left: 21em;
  animation-delay: 3.5s;
}

.footprint:nth-child(9) {
  top: 12em; /* Slightly steeper */
  left: 24em;
  animation-delay: 4s;
}

.footprint:nth-child(10) {
  top: 13.5em; /* Slightly steeper */
  left: 27em;
  animation-delay: 4.5s;
}

.footprint:nth-child(11) {
  top: 15em; /* Slightly steeper */
  left: 30em;
  animation-delay: 5s;
}

.footprint:nth-child(12) {
  top: 16.5em; /* Slightly steeper */
  left: 33em;
  animation-delay: 5.5s;
}

.footprint:nth-child(13) {
  top: 18em; /* Slightly steeper */
  left: 36em;
  animation-delay: 6s;
}

.footprint:nth-child(14) {
  top: 19.5em; /* Slightly steeper */
  left: 39em;
  animation-delay: 6.5s;
}

.footprint:nth-child(15) {
  top: 21em; /* Slightly steeper */
  left: 42em;
  animation-delay: 7s;
}

.footprint:nth-child(16) {
  top: 22.5em; /* Slightly steeper */
  left: 45em;
  animation-delay: 7.5s;
}

.footprint:nth-child(17) {
  top: 24em; /* Slightly steeper */
  left: 48em;
  animation-delay: 8s;
}

@keyframes walk {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media only screen and (max-width: 1080px) {
  .footprints-animation {
    display: none; /* Hide footprints on mobile */
  }
}
