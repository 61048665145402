#footer {
  display: block;
  position: relative;
  width: 100%;
  height: fit-content;
  padding: 4em 0; /* Increased padding to lower the text */
  background: url("../../Assets/Images/Branding/Grass.svg") no-repeat center
      center,
    rgb(219, 232, 196);
  background-size: cover;

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }

  .footer-wrapper {
    display: flex;
    position: relative;
    height: fit-content;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 1.5em 0;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    color: #fff;

    > .footer-section {
      display: flex;
      position: relative;
      width: fit-content;
      max-width: 20%;
      height: fit-content;
      padding: 0.5em 1em;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      font-weight: bold;

      > .footer-header {
        display: flex;
        position: relative;
        width: 100%;
        height: fit-content;
        justify-content: flex-end;
        flex-direction: row;
        margin-bottom: 1em;
        margin-top: 10em;

        span {
          width: 30%;

          > .sxlogo {
            display: flex;
            position: relative;
            right: 8.5em;
          }

          > .dsgtlogo {
            display: block;
            position: relative;
            width: 8em;
          }
        }

        > .major {
          margin: 0;
        }
      }

      > .footer-links {
        display: flex;
        position: relative;
        width: 100%;
        height: fit-content;
        flex-direction: column;
        float: right;
      }
    }
  }

  .major {
    display: block;
    position: relative;
    height: fit-content;
    width: fit-content;
    text-align: right;
    font-size: 2em; /* Reduced font size */
    font-weight: 700;
    line-height: 1.2em;
  }

  .minor {
    display: block;
    position: relative;
    height: fit-content;
    width: fit-content;
    text-align: right;
    font-size: 1.3em; /* Reduced font size */
    font-weight: 500;
    line-height: 1.2em;
    margin: 0;
  }

  .mini {
    display: block;
    position: relative;
    width: 100%;
    height: fit-content;
    text-align: right;
    font-size: 1em; /* Reduced font size */
    font-weight: bold;
    line-height: 1.3em;
    transition: 0.3s all ease;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .footer-fossil {
    position: absolute;
    width: 7em; /* Increased size */
    height: auto;

    &.shell {
      top: 5em;
      left: 1em; /* Moved left */
    }

    &.skull {
      top: 10em; /* Brought up slightly */
      left: 18%;
      width: 10em; /* Larger size */
    }

    &.spine {
      top: 6em; /* Moved further up */
      left: 35%;
      width: 8em; /* Larger size */
    }

    &.fish {
      top: 15em; /* Moved lower */
      left: 65%; /* Moved under Hacklytics */
      width: 8em; /* Adjusted size */
    }

    &.leaf {
      bottom: 3em;
      left: 30%;
    }
  }
}

@media screen and (max-width: 768px) {
  #footer {
    .footer-wrapper {
      .footer-section {
        padding: 0;
        max-width: 15%;

        .footer-header {
          margin-top: 5em;
          span {
            width: 30%;

            .sxlogo {
              display: block;
              position: relative;
              width: 8em;
              right: 70px;
            }

            .dsgtlogo {
              display: block;
              position: relative;
              width: 4em;
              right: 8px;
            }
          }
        }
      }
    }

    .minor {
      font-size: 0.8em; /* Further reduced font size for smaller screens */
    }

    .major {
      font-size: 1em; /* Further reduced font size for smaller screens */
    }

    .mini {
      font-size: 0.6em; /* Further reduced font size for smaller screens */
    }

    .footer-fossil {
      width: 5em; /* Reduced size for mobile screens */

      &.shell {
        top: 7em; /* Lowered */
        left: 1em; /* Adjusted position */
      }

      &.skull {
        top: 7em; /* Moved up */
        left: 15%;
        width: 7em; /* Adjusted size */
      }

      &.spine {
        top: 8em; /* Lowered position */
        left: 30%;
        width: 6em; /* Slightly larger size */
      }

      &.fish {
        top: 12em; /* Moved higher */
        left: 75%; /* Moved to the right */
        width: 5em; /* Adjusted size */
      }

      &.leaf {
        bottom: 2em;
        left: 25%;
        width: 5em; /* Adjusted size */
      }
    }
  }
}
