.section {
    display: block;
    position: relative;
    height: fit-content;
    width: 100%;

    > .section-wrapper {
        display: flex;
        position: relative;
        width: 100%;
        height: 100%;
        min-height: 96vh;
        max-width: 1400px;
        margin: 0 auto;
        padding: 3em 0;
        
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (max-width: 1000px) {
    .section {
        > .section-wrapper {
            min-height: 10vh;
        }
    }
}

// mobile
@media only screen and (max-width: 600px) {
    .section {
        > .section-wrapper {
            min-height: 10vh;
        }
    }
}