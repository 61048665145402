.header-with-pterodactyl {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  margin-bottom: 4vh;
}

.pterodactyl {
  position: absolute;
  left: 5%; /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%);
  width: 50px; /* Adjust size as needed */
  height: auto;
}

.major {
  font-size: 3em;
  font-weight: 700;
  font-family: "Changa", sans-serif;
  text-align: center;
  width: 100%;
}

.wood-banner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 4em;
}

.banner-img {
  width: 100%;
  max-width: 600px;
}
