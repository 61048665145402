#clouds {
    position: absolute;
    z-index: 3;
    margin-top: 12em;

    > .marquee {
        opacity: 80%;
        > .scrolling-cloud-left {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            animation: marqueeLeft 20s linear infinite;
        }

        > .scrolling-cloud-right {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            animation: marqueeRight 30s linear infinite;
        }
    }
}

@keyframes marqueeLeft {
    0% {
        transform: translate3d(-20%, 0, 0);
    }
    100% {
        transform: translate3d(-30%, 0, 0);
    }
}

@keyframes marqueeRight {
    0% {
        transform: translate3d(-50%, 0, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}

@media only screen and (max-width: 600px) {
    #clouds {
        font-size: 10px;
    }

    .scrolling-cloud-left {
        width: 500px;
        height: 500px;
    }
}