.track-card {
  display: block;
  position: relative;
  width: 40%; /* Increased width to make it more pill-shaped */
  min-width: 300px;
  height: 20vh; /* Adjusted height to ensure text fits well */
  min-height: 60px; /* Adjusted min-height to ensure text fits well */
  perspective: 1000px;
  cursor: pointer;
  margin-bottom: 1em; /* Added margin for spacing */

  .card-check {
    display: none;

    &:checked ~ .track-card-inner {
      transform: rotateY(180deg);
    }
  }
}

.track-card-inner {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  transform: none;
  text-align: center;
  cursor: pointer;
}

.track-card-front,
.track-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1em;
  box-sizing: border-box;
  border-radius: 50px; /* More pill-shaped */
  border: 6px solid #f3ae4a; /* Thicker yellow border */
  font-family: "Changa", sans-serif; /* Use Changa font */
}

.track-card-front {
  > .airplane-left,
  > .airplane-right {
    display: none; /* Hide airplane images */
  }

  > .front-style {
    display: flex;
    position: relative;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Center text horizontally */

    > .major-card {
      margin: 0;
      font-size: 1.2em; /* Adjusted font-size to ensure it fits well */
      font-weight: bold;
      padding: 0 10px; /* Added padding for better spacing */
    }

    > .major-card-sub {
      margin: 0;
      font-size: 1em;
      font-weight: normal;
      padding: 0 10px; /* Added padding for better spacing */
    }

    > .vector-img {
      position: absolute;
      right: 10px; /* Adjust as needed */
      width: 40px; /* Adjust size as needed */
      height: auto;
    }
  }
  text-align: center;
  background: rgba(255, 255, 255, 0.8); /* Light background */
  color: #000000;
}

.track-card-back {
  background: rgba(255, 255, 255, 0.8); /* Light background */
  color: #000000;
  transform: rotateY(180deg);
  font-size: 0.8em;
  text-align: left;
  border-radius: 50px; /* More pill-shaped */
  border: 6px solid #f3ae4a; /* Thicker yellow border */

  > .minor {
    font-size: 1.5em;
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .track-card {
    width: 80%;
    .track-card-back {
      font-size: 0.9em;
    }
  }
}
