#coordinates {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;

    > .marquee {

        &:hover {
            > .scrolling-text-left {
                animation-duration: 10s;
                animation-timing-function: linear;
                animation-fill-mode: forwards;
            }

            > .scrolling-text-right {
                animation-duration: 10s;
                animation-timing-function: linear;
                animation-fill-mode: forwards;
            }
        }

        > .scrolling-text-left {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            animation: marqueeLeft 30s linear infinite;
        }

        > .scrolling-text-right {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            animation: marqueeRight 30s linear infinite;
        }
    }
}

@keyframes marqueeLeft {
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(-50%, 0, 0);
    }
}

@keyframes marqueeRight {
    0% {
        transform: translate3d(-50%, 0, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}

@media only screen and (max-width: 600px) {
    #coordinates {
        font-size: 10px;
    }
}